var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "column-select" },
    [
      _c("Dropdown", {
        staticClass: "column-select-dropdown",
        attrs: {
          items: _vm.dropdownOptions,
          disabled: _vm.loading,
          "select-closes": false,
          "max-height": "50vh"
        },
        scopedSlots: _vm._u([
          {
            key: "trigger",
            fn: function() {
              return [
                _c(
                  "b-tooltip",
                  {
                    attrs: {
                      label: "Select columns",
                      position: "is-left",
                      type: "is-dark"
                    }
                  },
                  [
                    _c("Button", {
                      staticClass: "column-select-trigger",
                      attrs: { icon: "eye-open", type: "grey" }
                    })
                  ],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "item",
            fn: function(ref) {
              var item = ref.item
              return [
                _c(
                  "div",
                  { staticClass: "column-select-item" },
                  [
                    _c("Checkbox", {
                      staticClass: "column-select-item-check",
                      attrs: { value: _vm.selected.includes(item.id) }
                    }),
                    _vm._v(" " + _vm._s(item.title) + " ")
                  ],
                  1
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }