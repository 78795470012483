<template>
  <div class="resource">
    <div class="resource-preview">
      <img
        class="resource-preview-icon"
        :src="require(`@/assets/icons/${isGenerated ? 'ai' : 'upload'}.svg`)"
      />
      <p class="resource-preview-text">
        {{ isGenerated ? 'Generating' : 'Uploading' }}...
      </p>
    </div>
    <div class="resource-info">
      <DocumentTag
        :mimetype="resource.integrationfile.mimetype"
        class="resource-info-icon"
      />
      <p class="resource-info-title" :title="resource.title">
        {{ resource.title }}
      </p>
      <div class="resource-info-btns"></div>
    </div>
    <div class="resource-sub">{{ subTitle }}</div>
  </div>
</template>

<script>
import DocumentTag from '@c/shared/molecules/tags/DocumentTag.vue'
import { formatDateWTime } from '@/util'

export default {
  name: 'ORIResourceGenerating',
  components: {
    DocumentTag
  },
  props: {
    resource: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    poller: null
  }),
  computed: {
    creationSource() {
      return this.resource.integrationfile.creation_source
    },
    isGenerated() {
      return this.creationSource === 'generated'
    },
    creationDate() {
      return formatDateWTime(
        this.resource.integrationfile.external_created_date
      )
    },
    subTitle() {
      return `${this.isGenererated ? 'Generation' : 'Upload'} started ${
        this.creationDate
      }`
    }
  },
  created() {
    this.poller = setInterval(() => this.$emit('refresh'), 20 * 1000)
  },
  beforeDestroy() {
    clearInterval(this.poller)
  }
}
</script>

<style lang="scss" scoped>
.resource {
  &-preview {
    width: 100%;
    aspect-ratio: 16 / 9;
    background: #f1f2f2;
    border-radius: 4px;
    border: 1px solid rgba(#000, 8%);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column nowrap;
    gap: 0.5rem;

    &-icon {
      height: 1.2rem;
      filter: brightness(0) saturate(100%) invert(37%) sepia(5%) saturate(584%)
        hue-rotate(165deg) brightness(102%) contrast(84%);
    }

    &-text {
      color: #60666b;
    }
  }

  &-info {
    display: grid;
    max-width: 100%;
    grid-template-columns: 1.5rem 1fr auto;
    align-items: center;
    padding-top: 0.5rem;

    &-icon {
      width: 1.2rem;
      display: inline-flex;
      vertical-align: middle;
      align-items: center;
    }

    &-title {
      color: $primary;
      display: inline-block;
      vertical-align: middle;
      font-weight: 600;
      text-align: justify;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-btns {
      height: 30.75px;
    }
  }

  &-sub {
    display: flex;
    flex-flow: row nowrap;
    gap: 0.5rem;
    align-items: center;
    color: #9098ae;
    font-size: 0.9rem;
  }
}
</style>
