var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "portfolio", class: { padded: _vm.isOverview } },
    [
      _vm.isOverview
        ? _c("Tabs", {
            attrs: {
              tabs: _vm.tabs,
              selected: function(tab) {
                return tab.key === _vm.currentTab
              },
              padding: "2.5rem"
            },
            on: { select: _vm.switchTab },
            scopedSlots: _vm._u(
              [
                {
                  key: "buttons",
                  fn: function() {
                    return [
                      _c("ViewSwitch", {
                        attrs: { views: _vm.views, selected: _vm.currentView },
                        on: { view: _vm.switchView }
                      })
                    ]
                  },
                  proxy: true
                },
                {
                  key: "tab",
                  fn: function(ref) {
                    var tab = ref.tab
                    return [
                      tab.key === _vm.currentTab
                        ? _c(_vm.tabComponent, {
                            tag: "component",
                            attrs: { type: tab.key }
                          })
                        : _vm._e()
                    ]
                  }
                }
              ],
              null,
              false,
              703565708
            )
          })
        : _vm.currentTab
        ? _c("ORIDetail", {
            ref: "oridetail",
            attrs: { type: _vm.currentTab.slice(0, -1) }
          })
        : _vm._e(),
      _c("ResourceModal", {
        scopedSlots: _vm._u([
          {
            key: "subHeader",
            fn: function(ref) {
              var resource = ref.resource
              return [
                _c("PortfolioResourceModalPublish", {
                  attrs: { resource: resource },
                  on: { publish: _vm.handleResourcePublish }
                })
              ]
            }
          }
        ])
      }),
      _vm.showCollectSlides ? _c("CustomSlideCreator") : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }