var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ori-section" },
    [
      _vm.name
        ? _c(
            "div",
            { staticClass: "ori-section-header" },
            [
              _c("span", { staticClass: "ori-section-header-title" }, [
                _vm._v(_vm._s(_vm.name))
              ]),
              _vm.type === "resources"
                ? _c(
                    "b-tooltip",
                    {
                      attrs: {
                        label:
                          "This section is automatically synchronised with your repository every few hours. If a recent change is not visible yet, please wait a bit longer.",
                        position: "is-top",
                        type: "is-dark",
                        multilined: ""
                      }
                    },
                    [
                      _c("Button", {
                        attrs: {
                          icon: "info-medium",
                          type: "white",
                          size: "xxs"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "ori-section-header-btns" },
                [
                  _vm.showAdd && !_vm.isEmpty
                    ? _c("Button", {
                        attrs: { text: _vm.addButtonText },
                        on: {
                          click: function($event) {
                            return _vm.$emit("add")
                          }
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c("ORISectionPills", {
        attrs: {
          items: _vm.filteredItems,
          type: _vm.type,
          "extra-filters":
            _vm.type === "resources" ? _vm.extraResourceFilters : []
        }
      }),
      _c(
        "div",
        [
          !_vm.useDragGrid
            ? _c(
                "div",
                {
                  staticClass: "ori-section-content",
                  class: {
                    "is-grid": _vm.useGridDisplay
                  },
                  attrs: { id: _vm.type + "-contentgrid" }
                },
                _vm._l(_vm.visibleItems, function(item, idx) {
                  return _c(
                    "div",
                    { key: item.uuid, staticClass: "ori-section-content-item" },
                    [
                      _c(
                        _vm.cardComponent[_vm.type],
                        _vm._b(
                          {
                            tag: "component",
                            on: {
                              published: function(res) {
                                return _vm.$emit("published", res)
                              }
                            }
                          },
                          "component",
                          _vm.cardProps(item, idx),
                          false
                        )
                      ),
                      _c(
                        "div",
                        { staticClass: "ori-section-content-item-btns" },
                        [
                          (_vm.canWriteOri || _vm.isOwner) && _vm.removable
                            ? _c("Button", {
                                attrs: {
                                  icon: "bin",
                                  type: "white",
                                  size: "xs",
                                  loading: _vm.removeLoading.includes(item.uuid)
                                },
                                on: {
                                  click: function() {
                                    return _vm.handleRemove(item)
                                  }
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                }),
                0
              )
            : _c("DraggableList", {
                staticClass: "ori-section-content",
                class: {
                  "is-grid": _vm.useGridDisplay,
                  "compact-grid": _vm.type === "resources"
                },
                attrs: {
                  id: _vm.type + "-contentgrid",
                  items: _vm.visibleItems,
                  "item-key": _vm.ori.uuid + "-" + _vm.type,
                  draggable:
                    (_vm.canWriteOri || _vm.isOwner) &&
                    _vm.filteredItems.length === _vm.items.length &&
                    !_vm.hasTempItems,
                  "handle-bordered": true,
                  "handle-overlaps": true,
                  "item-style": { padding: 0, borderRadius: "8px" }
                },
                on: {
                  reorder: function(o) {
                    return _vm.$emit("reorder", o)
                  }
                },
                scopedSlots: _vm._u([
                  {
                    key: "item",
                    fn: function(ref) {
                      var item = ref.item
                      var index = ref.index
                      return [
                        _c(
                          "div",
                          { staticClass: "ori-section-content-item" },
                          [
                            _c(
                              _vm.cardComponent[_vm.type],
                              _vm._b(
                                {
                                  tag: "component",
                                  on: {
                                    published: function(res) {
                                      return _vm.$emit("published", res)
                                    },
                                    refresh: function() {
                                      return _vm.$emit("refresh")
                                    }
                                  }
                                },
                                "component",
                                _vm.cardProps(item, index),
                                false
                              )
                            ),
                            _c(
                              "div",
                              { staticClass: "ori-section-content-item-btns" },
                              [
                                (_vm.canWriteOri || _vm.isOwner) &&
                                _vm.removable &&
                                _vm.showRemove(item)
                                  ? _c("Button", {
                                      attrs: {
                                        icon: "bin",
                                        type: "white",
                                        size: "xs",
                                        loading: _vm.removeLoading.includes(
                                          item.uuid
                                        )
                                      },
                                      on: {
                                        click: function() {
                                          return _vm.handleRemove(item)
                                        }
                                      }
                                    })
                                  : _vm._e(),
                                _vm.type === "resources" &&
                                _vm.hasDownloadableMimetype(item) &&
                                item.uuid
                                  ? _c("ResourceCollectMenu", {
                                      attrs: {
                                        "resource-id": item.uuid,
                                        "resource-trace-id": item.trace_id,
                                        "button-type": "primary",
                                        size: "xs"
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              })
        ],
        1
      ),
      _vm.hasShowMore && _vm.filteredItems.length > _vm.showMoreCutoff
        ? _c("Button", {
            staticClass: "ori-section-showmore",
            attrs: {
              text: _vm.showMore ? "Show less" : "Show more",
              type: "light-solid",
              size: "s"
            },
            nativeOn: {
              click: function($event) {
                $event.stopPropagation()
                _vm.showMore = !_vm.showMore
              }
            }
          })
        : _vm._e(),
      _vm.isEmpty
        ? _c(
            "div",
            { staticClass: "ori-section-empty" },
            [
              _c("p", { staticClass: "ori-section-empty-header" }, [
                _vm._v(_vm._s(_vm.emptyMessage.header))
              ]),
              _c("p", { staticClass: "ori-section-empty-subheader" }, [
                _vm._v(_vm._s(_vm.emptyMessage.subheader))
              ]),
              _vm.showAdd
                ? _c("Button", {
                    attrs: { text: _vm.addButtonText },
                    on: {
                      click: function($event) {
                        return _vm.$emit("add")
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }