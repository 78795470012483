<template>
  <div class="portfolio" :class="{ padded: isOverview }">
    <Tabs
      v-if="isOverview"
      :tabs="tabs"
      :selected="(tab) => tab.key === currentTab"
      padding="2.5rem"
      @select="switchTab"
    >
      <template #buttons>
        <ViewSwitch :views="views" :selected="currentView" @view="switchView" />
      </template>
      <template #tab="{ tab }">
        <component
          :is="tabComponent"
          v-if="tab.key === currentTab"
          :type="tab.key"
        />
      </template>
    </Tabs>
    <ORIDetail
      v-else-if="currentTab"
      ref="oridetail"
      :type="currentTab.slice(0, -1)"
    />
    <ResourceModal>
      <template #subHeader="{ resource }">
        <PortfolioResourceModalPublish
          :resource="resource"
          @publish="handleResourcePublish"
        />
      </template>
    </ResourceModal>
    <CustomSlideCreator v-if="showCollectSlides" />
  </div>
</template>

<script>
import Tabs from '@c/library/Tabs.vue'
import Button from '@c/library/Button.vue'
import ViewSwitch from '@c/library/ViewSwitch.vue'
import PortfolioTableTab from './portfolio/PortfolioTableTab.vue'
import PortfolioGridTab from './portfolio/PortfolioGridTab.vue'
import PortfolioResourceModalPublish from './portfolio/PortfolioResourceModalPublish.vue'
import ORIDetail from './ori/ORIDetail.vue'
import { mapGetters, mapActions } from 'vuex'
import ResourceModal from '@c/shared/molecules/object-visualisations/resource/subcomponents/ResourceModal.vue'
import CustomSlideCreator from '@c/shared/molecules/object-visualisations/resource/subcomponents/CustomSlideCreator.vue'

export default {
  name: 'Portfolio',
  components: {
    Tabs,
    Button,
    ViewSwitch,
    ORIDetail,
    ResourceModal,
    CustomSlideCreator,
    PortfolioResourceModalPublish
  },
  data: () => ({
    views: [
      { icon: 'grid', key: 'grid' },
      { icon: 'list', key: 'table' }
    ],
    currentView: '',
    tabs: [
      { name: 'Offerings', key: 'offerings' },
      { name: 'Cases', key: 'references' },
      { name: 'Inspiration', key: 'inspirations' }
    ],
    currentTab: ''
  }),
  computed: {
    ...mapGetters([
      'currentWorkspace',
      'canWriteOri',
      'canReadOri',
      'showCollectSlides'
    ]),
    isOverview() {
      return !this.$route.params.ori_id
    },
    routeTab() {
      return this.$route.params.ori_type
    },
    tabComponent() {
      return {
        table: PortfolioTableTab,
        grid: PortfolioGridTab
      }[this.currentView]
    }
  },
  watch: {
    routeTab(tab) {
      if (tab !== this.currentTab) this.switchTab({ key: tab })
    }
  },
  created() {
    if (
      !this.currentWorkspace ||
      this.currentWorkspace.uuid !== this.$route.params.workspace_id
    ) {
      this.setCurrentWorkspace(this.$route.params.workspace_id)
    }
    if (!(this.canWriteOri || this.canReadOri)) {
      this.$router.push({ name: 'home' })
    }
    this.initializeState()
  },
  methods: {
    ...mapActions(['setCurrentWorkspace']),
    initializeState() {
      if (this.$route.query.view)
        this.switchTab({ key: this.$route.params.ori_type || this.tabs[0].key })
      this.switchView({ key: this.$route.query?.view || this.views[0].key })
    },
    switchTab(t) {
      const tab = t.key
      this.currentTab = tab
      if (this.$route.params.ori_type !== tab)
        this.$router[this.$route.params?.ori_type ? 'push' : 'replace']({
          params: { ori_type: tab },
          query: {
            ...(this.$route.query.view ? { view: this.$route.query.view } : {})
          }
        })
    },
    switchView(v) {
      const view = v.key
      if (view !== this.view) {
        this.currentView = view
        if (this.$route.query?.view !== view)
          this.$router[this.$route.query?.view ? 'push' : 'replace']({
            query: {
              view,
              ...(this.$route.query.query
                ? { query: this.$route.query.query }
                : {})
            }
          })
      }
    },
    handleResourcePublish(resources) {
      this.$refs.oridetail.updateResources(resources)
    }
  }
}
</script>

<style lang="scss" scoped>
.portfolio {
  &.padded {
    padding-top: 1.5rem;
  }
}
</style>
