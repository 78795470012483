<template>
  <div class="column-select">
    <Dropdown
      :items="dropdownOptions"
      :disabled="loading"
      :select-closes="false"
      max-height="50vh"
      class="column-select-dropdown"
    >
      <template #trigger>
        <b-tooltip label="Select columns" position="is-left" type="is-dark">
          <Button icon="eye-open" type="grey" class="column-select-trigger" />
        </b-tooltip>
      </template>
      <template #item="{ item }">
        <div class="column-select-item">
          <Checkbox
            :value="selected.includes(item.id)"
            class="column-select-item-check"
          />
          {{ item.title }}
        </div>
      </template>
    </Dropdown>
  </div>
</template>

<script>
import Dropdown from '@c/library/Dropdown.vue'
import Button from '@c/library/Button.vue'
import Checkbox from '@c/library/Checkbox.vue'

export default {
  name: 'PortfolioColumnDropdown',
  components: { Dropdown, Button, Checkbox },
  props: {
    options: {
      type: Array,
      default: () => []
    },
    selected: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    dropdownOptions() {
      return this.options.map((option) => ({
        ...option,
        callback: () => this.handleSelect(option)
      }))
    }
  },
  methods: {
    handleSelect(option) {
      const newSelection = this.options.reduce((acc, curr) => {
        if (curr.id === option.id) {
          return this.selected.includes(curr.id) ? acc : [...acc, curr.id]
        }
        return this.selected.includes(curr.id) ? [...acc, curr.id] : acc
      }, [])
      this.$emit('select', newSelection)
    }
  }
}
</script>

<style lang="scss" scoped>
.column-select {
  position: relative;

  &-trigger {
    filter: brightness(0) !important;
  }

  &-dropdown {
    position: absolute;
    top: -0.5rem;
    right: 0;
    z-index: 100;
  }

  &-item {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 1rem;
    min-width: min(15rem, 85vw);
    padding: 0.25rem 0;
    font-weight: 400;

    &-check {
      pointer-events: none;
    }
  }
}
</style>
