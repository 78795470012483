<template>
  <div class="ori-edit">
    <div
      v-for="key in visibleKeys"
      :key="key"
      class="ori-edit-item"
    >
      <TextInput
        v-if="key === 'name'"
        v-model="name"
        :title="names[key].capitalize()"
        :placeholder="placeholders[key]"
      />
      <TextInput
        v-else-if="key === 'url'"
        v-model="url"
        :title="names[key].capitalize()"
        :placeholder="placeholders[key]"
      />
      <AvatarUpload
        v-else-if="key === 'image'"
        :image="image"
        :width="512"
        :max-size="5"
        :can-generate="canGenerate"
        :generate-content="content"
        @input="
          (url, img) => {
            image = url
            imageBlob = img
          }
        "
      />
      <ORIOwnersEdit
        v-else-if="key === 'owners'"
        title="Owners"
        :item="item"
        @submit="owners = $event"
      />
      <OfferingParentEdit
        v-else-if="key === 'parent'"
        :key="parentType === 'offering' ? parent.uuid : category.uuid"
        :offering-id="item.uuid"
        :parent-id="parentType === 'offering' ? parent.uuid : ''"
        :category="parentType === 'category' ? category : undefined"
        @select="handleParentSelect"
      />
    </div>
  </div>
</template>

<script>
import TextInput from '@c/library/TextInput.vue'
import AvatarUpload from '@c/library/AvatarUpload.vue'
import ORIOwnersEdit from './ORIOwnersEdit.vue'
import { mapGetters } from 'vuex'
import OfferingParentEdit from '../offerings/OfferingParentEdit.vue'

const arraysEqual = (a1, a2) =>
  a1.length === a2.length && a1.every((o, idx) => o.uuid === a2[idx].uuid)

const baseState = {
  image: '',
  imageBlob: undefined,
  name: '',
  content: '',
  url: '',
  owners: [],
  parent: undefined,
  category: undefined
}

export default {
  name: 'ORIEdit',
  components: {
    TextInput,
    AvatarUpload,
    ORIOwnersEdit,
    OfferingParentEdit
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      required: true
    }
  },
  data: () => ({
    ...baseState,
    previousState: undefined,
    changesKey: 0,
    parentType: ''
  }),
  computed: {
    ...mapGetters(['oriManagementEnabled']),
    typeName() {
      return {
        offering: 'offering',
        reference: 'case',
        inspiration: 'inspirational content'
      }[this.type]
    },
    canGenerate() {
      return !!this.content.length
    },
    keys() {
      let itemKeys = Object.keys(this.item)
      return Object.keys(baseState).reduce((acc, key) => {
        if (itemKeys.includes(key)) acc.push(key)
        return acc
      }, [])
    },
    visibleKeys() {
      let keys = [...this.keys]
      keys = keys.filter((k) => k !== 'content')
      if (keys.includes('parent')) keys = keys.filter((k) => k !== 'category')
      return keys
    },
    names() {
      return this.visibleKeys.reduce((acc, key) => {
        if (key === 'url') acc[key] = 'link'
        else acc[key] = key
        return acc
      }, {})
    },
    placeholders() {
      return this.visibleKeys.reduce((acc, key) => {
        acc[key] = `Enter ${this.typeName} ${this.names[key]}`
        return acc
      }, {})
    },
    changedKeys() {
      let changes = []
      for (let idx = 0; idx < this.visibleKeys.length; idx++) {
        const key = this.visibleKeys[idx]
        if (
          (key === 'owners' &&
            !arraysEqual(
              this.owners,
              this.getVisible(this.previousState.owners)
            )) ||
          (key === 'parent' &&
            (this.parent?.uuid !== this.previousState.parent?.uuid ||
              this.category?.uuid !== this.previousState.category?.uuid)) ||
          (!['owners', 'parent'].includes(key) &&
            this[key] !== this.previousState[key])
        ) {
          changes.push(key)
        }
      }
      return changes
    }
  },
  watch: {
    item: {
      handler() {
        this.initState()
      },
      deep: true
    }
  },
  created() {
    this.initState()
  },
  methods: {
    initState() {
      const newState = this.keys.reduce((acc, key) => {
        if (key === 'owners') acc[key] = this.getVisible(this.item[key])
        else acc[key] = this.item[key] || baseState[key] || ''
        return acc
      }, {})
      this.previousState = JSON.parse(JSON.stringify(newState))
      Object.assign(this, newState)
      if (this.keys.includes('parent'))
        this.parentType = this.item.parent?.uuid ? 'offering' : 'category'
    },
    getVisible(owners) {
      return owners.filter((owner) => !owner.is_inherited)
    },
    submit() {
      this.$emit(
        'submit',
        this.changedKeys.reduce((acc, key) => {
          if (key === 'owners')
            acc.owner_ids = this.owners.map((o) => o.uuid)
          else if (key === 'image') acc.image = this.imageBlob
          else if (key === 'parent') {
            if (
              this.category?.uuid !== this.previousState.category?.uuid ||
              this.parent?.uuid === undefined
            )
              acc.category_id = this.category?.uuid
            else acc.parent_id = this.parent?.uuid
          } else acc[key] = this[key]
          return acc
        }, {})
      )
    },
    handleParentSelect(parent) {
      if (parent.type === 'category') {
        if (this.category?.uuid === parent.uuid) {
          this.parent = undefined
        }
        this.category = parent
      } else {
        this.parent = parent
      }
      this.parentType = parent.type
    }
  }
}
</script>

<style scoped lang="scss">
.ori-edit {
  display: flex;
  flex-flow: column nowrap;
  gap: 1.5rem;

  &-item {
    &-conf {
      width: 100%;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      padding: 0.5rem;
      border-radius: 4px;
      border: 1px solid rgba(0, 0, 0, 0.08);

      &-value {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        gap: 0.5rem;

        &-icon {
          height: 1.2rem;
        }
      }

      &-title {
        font-weight: 600;
        padding-bottom: 0.5rem;
      }

      &-icon {
        height: 1.2rem;
      }
    }
  }
}
</style>
