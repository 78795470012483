<template>
  <b-modal :active="visible" @close="() => $emit('close')">
    <div v-if="visible" class="ori-img-modal">
      <div class="ori-img-modal-content">
        <p class="ori-img-modal-title">Change image</p>
        <AvatarUpload
          :image="image"
          :width="512"
          :max-size="5"
          :can-generate="canGenerate"
          :generate-content="content"
          @input="
            (url, img) => {
              image = url
              imageBlob = img
            }
          "
        />
      </div>
      <div class="ori-img-modal-footer">
        <Button
          text="Cancel"
          type="white"
          :disabled="loading"
          @click="() => $emit('close')"
        />
        <Button
          text="Save"
          :loading="loading"
          :disabled="!imageChanged"
          @click="saveImage"
        />
      </div>
    </div>
  </b-modal>
</template>

<script>
import AvatarUpload from '@c/library/AvatarUpload.vue'
import Button from '@c/library/Button.vue'
import { mapActions } from 'vuex'

export default {
  name: 'ORIImageModal',
  components: { AvatarUpload, Button },
  props: {
    item: {
      type: Object,
      default: () => undefined
    },
    type: {
      type: String,
      default: 'offering',
      validator: (val) => ['offering', 'reference', 'inspiration'].includes(val)
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    image: '',
    imageBlob: undefined,
    loading: false
  }),
  computed: {
    canGenerate() {
      return !!this.item?.content?.length
    },
    content() {
      return this.item?.content
    },
    imageChanged() {
      return this.image !== this.item?.image
    }
  },
  watch: {
    visible(val) {
      if (val) {
        this.image = this.item?.image
      }
    }
  },
  created() {
    this.image = this.item?.image
  },
  methods: {
    ...mapActions([
      'setOfferingImage',
      'setReferenceImage',
      'setInspirationImage'
    ]),
    async saveImage() {
      try {
        if (this.loading) return
        this.loading = true
        if (!this.imageChanged) {
          this.$emit('close')
          return
        }
        const imageFunction = {
          offering: this.setOfferingImage,
          reference: this.setReferenceImage,
          inspiration: this.setInspirationImage
        }[this.type]
        const res = await imageFunction({
          workspace_id: this.$route.params.workspace_id,
          ori_id: this.item.uuid,
          image: this.imageBlob
        })
        this.$emit('edit', res)
      } catch (e) {
        this.$console.debug('Something went wrong while changing the image', e)
        this.$toast.error(e, `changing this ${this.type} image`)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.ori-img-modal {
  background: white;
  border-radius: 6px;
  width: min(35rem, 90vw);

  &-title {
    font-size: 1.25rem;
    font-weight: 700;
    padding-bottom: 1rem;
  }

  &-content {
    padding: 1rem 1.5rem;
  }

  &-footer {
    padding: 1rem 1.5rem;
    border-top: 1px solid rgba(#000, 0.08);
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    gap: 1rem;
    align-items: center;
  }
}

::v-deep .modal-content {
  width: unset !important;
}
</style>
