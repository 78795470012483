<template>
  <b-modal :active="visible" @close="closeModal">
    <div class="ori-init">
      <p class="ori-init-header">{{ header }}</p>
      <div>
        <div v-if="!step" class="ori-init-content">
          <TextInput v-model="name" title="Name" :placeholder="placeholder" />
          <ORIConfidentialityEdit
            v-if="type === 'reference'"
            :value="confidentiality"
            title="Confidentiality"
            @submit="confidentiality = $event"
          />
          <OrganisationSearch
            v-if="type === 'reference'"
            :organisation="account ? account.organisation : undefined"
            title="Organisation"
            class="ori-init-content-org"
            @input="handleAccountInput"
            @remove="handleAccountRemove"
            @loading="(l) => (accountLoading = l)"
          />
          <ORIOwnersEdit
            title="Owners"
            :default-owners="defaultOwners"
            @submit="owners = $event"
          />
        </div>
        <div v-else class="ori-init-content centered">
          <p class="ori-init-content-header">
            {{ typeName.capitalize() }} created successfully
          </p>
          <p class="ori-init-content-subheader">
            Do you want to add content to your {{ typeName }} now?
          </p>
          <component
            :is="cardComponent"
            v-bind="cardProps"
            class="ori-init-content-item"
          />
        </div>
      </div>
      <div class="ori-init-footer">
        <Button
          :text="cancelButtonText"
          type="white"
          :disabled="loading"
          @click="closeModal"
        />
        <Button
          :text="submitButtonText"
          :disabled="!name"
          :loading="loading || accountLoading"
          @click="submit"
        />
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import TextInput from '@c/library/TextInput.vue'
import Button from '@c/library/Button.vue'
import ORIOwnersEdit from './ORIOwnersEdit.vue'
import OrganisationSearch from '@/views-v2/organisations/OrganisationSearch.vue'
import ORIConfidentialityEdit from './ORIConfidentialityEdit.vue'
import OfferingCard from '../offerings/OfferingCard.vue'
import ReferenceCard from '../references/ReferenceCard.vue'
import InspirationCard from '../inspirations/InspirationCard.vue'

const defaultState = {
  step: 0,
  name: '',
  loading: false,
  owners: [],
  account: undefined,
  confidentiality: 'external',
  result: undefined,
  showDomainInput: false,
  domainInputOrganisation: undefined,
  domainInputLoading: false,
  accountLoading: false
}

export default {
  components: {
    TextInput,
    Button,
    ORIOwnersEdit,
    OrganisationSearch,
    ORIConfidentialityEdit
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      required: true,
      validator: (val) => ['offering', 'reference', 'inspiration'].includes(val)
    },
    parent: {
      type: Object,
      default: () => undefined
    },
    link: {
      type: Object,
      default: () => undefined
    },
    confirm: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      ...defaultState
    }
  },
  computed: {
    ...mapGetters(['currentWorkspaceMember']),
    defaultOwners() {
      return [{ ...this.currentWorkspaceMember }]
    },
    typeName() {
      return {
        offering: 'offering',
        reference: 'case',
        inspiration: 'inspirational content'
      }[this.type]
    },
    placeholder() {
      return `${this.typeName.capitalize()} name`
    },
    header() {
      return {
        offering: 'Create an offering',
        reference: 'Create a reference case',
        inspiration: 'Create inspirational content'
      }[this.type]
    },
    cardComponent() {
      return {
        offering: OfferingCard,
        reference: ReferenceCard,
        inspiration: InspirationCard
      }[this.type]
    },
    cardProps() {
      return {
        [this.type]: this.result,
        clickable: false
      }
    },
    cancelButtonText() {
      return this.step ? 'Close' : 'Cancel'
    },
    submitButtonText() {
      return this.step ? 'Add content' : 'Create'
    }
  },
  methods: {
    ...mapActions(['createOffering', 'createReference', 'createInspiration']),
    handleAccountInput(acc) {
      this.account = acc
    },
    handleAccountRemove() {
      this.account = undefined
    },
    submit() {
      if (this.step) {
        this.gotoItem(this.result)
      } else {
        this.createItem()
      }
    },
    gotoItem(item) {
      this.$router.push({
        name: 'portfolio',
        params: {
          workspace_id: this.$route.params.workspace_id,
          ori_type: `${this.type}s`,
          ori_id: item.uuid
        }
      })
      this.closeModal()
    },
    async createItem() {
      try {
        this.loading = true
        const createFunction = {
          offering: this.createOffering,
          reference: this.createReference,
          inspiration: this.createInspiration
        }[this.type]
        let props = {
          workspace_id: this.$route.params.workspace_id,
          name: this.name,
          owner_ids: this.owners.map((owner) => owner.uuid)
        }
        if (this.parent !== undefined) {
          const propId =
            this.parent.type === 'category' ? 'category_id' : 'parent_id'
          props = {
            ...props,
            [propId]: this.parent.uuid
          }
        }
        if (this.type === 'reference') {
          props = {
            ...props,
            confidentiality: this.confidentiality,
            ...(this.account ? { account_id: this.account?.uuid } : {})
          }
        }
        if (this.link) {
          props = {
            ...props,
            ...this.link
          }
        }
        const item = await createFunction(props)

        this.$emit('created', item)

        if (!this.confirm) {
          this.gotoItem(item)
        } else {
          this.result = item
          this.step = 1
        }
      } catch (e) {
        this.$console.debug('Something went wrong while creating item', e)
        this.$toast.error(e, `creating your ${this.typeName}`)
      } finally {
        this.loading = false
      }
    },
    closeModal() {
      this.$emit('close')
      Object.assign(this, defaultState)
    }
  }
}
</script>
<style scoped lang="scss">
.ori-init {
  width: min(45rem, 90vw);
  background: white;
  border-radius: 6px;

  &-header {
    padding: 1.5rem;
    font-size: 1.25rem;
    font-weight: 700;
    border-bottom: 1px solid rgba(#000, 0.08);
  }

  &-content {
    padding: 1.5rem;
    display: flex;
    flex-flow: column nowrap;
    gap: 1rem;

    &.centered {
      align-items: center;
    }

    &-header {
      font-weight: 600;
    }

    &-subheader {
      color: #60666b;
    }

    &-item {
      min-width: max(10rem, 50%);
    }

    &-org {
      position: relative;
      z-index: 999;
    }
  }

  &-footer {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem;
    border-top: 1px solid rgba(#000, 0.08);
    padding: 1.5rem;
  }
}

::v-deep .modal-content {
  width: unset !important;
}
</style>
