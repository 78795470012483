<template>
  <div class="ori-section">
    <div v-if="name" class="ori-section-header">
      <span class="ori-section-header-title">{{ name }}</span>
      <b-tooltip
        v-if="type === 'resources'"
        label="This section is automatically synchronised with your repository every few hours. If a recent change is not visible yet, please wait a bit longer."
        position="is-top"
        type="is-dark"
        multilined
      >
        <Button icon="info-medium" type="white" size="xxs" />
      </b-tooltip>
      <div class="ori-section-header-btns">
        <Button
          v-if="showAdd && !isEmpty"
          :text="addButtonText"
          @click="$emit('add')"
        />
      </div>
    </div>
    <ORISectionPills
      :items="filteredItems"
      :type="type"
      :extra-filters="type === 'resources' ? extraResourceFilters : []"
    />
    <div>
      <div
        v-if="!useDragGrid"
        :id="`${type}-contentgrid`"
        class="ori-section-content"
        :class="{
          'is-grid': useGridDisplay
        }"
      >
        <div
          v-for="(item, idx) in visibleItems"
          :key="item.uuid"
          class="ori-section-content-item"
        >
          <component
            :is="cardComponent[type]"
            v-bind="cardProps(item, idx)"
            @published="res => $emit('published', res)"
          />
          <div class="ori-section-content-item-btns">
            <Button
              v-if="(canWriteOri || isOwner) && removable"
              icon="bin"
              type="white"
              size="xs"
              :loading="removeLoading.includes(item.uuid)"
              @click="() => handleRemove(item)"
            />
          </div>
        </div>
      </div>
      <DraggableList
        v-else
        :id="`${type}-contentgrid`"
        :items="visibleItems"
        :item-key="`${ori.uuid}-${type}`"
        :draggable="
          (canWriteOri || isOwner) &&
            filteredItems.length === items.length &&
            !hasTempItems
        "
        :handle-bordered="true"
        :handle-overlaps="true"
        class="ori-section-content"
        :class="{
          'is-grid': useGridDisplay,
          'compact-grid': type === 'resources'
        }"
        :item-style="{ padding: 0, borderRadius: '8px' }"
        @reorder="o => $emit('reorder', o)"
      >
        <template #item="{ item, index }">
          <div class="ori-section-content-item">
            <component
              :is="cardComponent[type]"
              v-bind="cardProps(item, index)"
              @published="res => $emit('published', res)"
              @refresh="() => $emit('refresh')"
            />
            <div class="ori-section-content-item-btns">
              <Button
                v-if="(canWriteOri || isOwner) && removable && showRemove(item)"
                icon="bin"
                type="white"
                size="xs"
                :loading="removeLoading.includes(item.uuid)"
                @click="() => handleRemove(item)"
              />
              <ResourceCollectMenu
                v-if="
                  type === 'resources' &&
                    hasDownloadableMimetype(item) &&
                    item.uuid
                "
                :resource-id="item.uuid"
                :resource-trace-id="item.trace_id"
                button-type="primary"
                size="xs"
              />
            </div>
          </div>
        </template>
      </DraggableList>
    </div>
    <Button
      v-if="hasShowMore && filteredItems.length > showMoreCutoff"
      :text="showMore ? 'Show less' : 'Show more'"
      type="light-solid"
      size="s"
      class="ori-section-showmore"
      @click.native.stop="showMore = !showMore"
    />
    <div v-if="isEmpty" class="ori-section-empty">
      <p class="ori-section-empty-header">{{ emptyMessage.header }}</p>
      <p class="ori-section-empty-subheader">{{ emptyMessage.subheader }}</p>
      <Button v-if="showAdd" :text="addButtonText" @click="$emit('add')" />
    </div>
  </div>
</template>

<script>
import InspirationListItem from '@/views-v2/inspirations/InspirationListItem.vue'
import OfferingCard from '@/views-v2/offerings/OfferingCard.vue'
import ReferenceListItem from '@/views-v2/references/ReferenceListItem.vue'
import ORIResourceCard from './ORIResourceCard.vue'
import { mapGetters } from 'vuex'
import Button from '@c/library/Button.vue'
import ORISectionPills from './ORISectionPills.vue'
import ORITagEdit from './ORITagEdit.vue'
import DraggableList from '@c/library/DraggableList.vue'
import ResourceCollectMenu from '@c/shared/molecules/object-visualisations/resource/subcomponents/ResourceCollectMenu.vue'
import { mapActions } from 'vuex'
import { downloadableMimetypes, mimetypeToTypeName } from '@c/mimetypes'

export default {
  name: 'ORISection',
  components: {
    Button,
    ORISectionPills,
    ORITagEdit,
    DraggableList,
    ResourceCollectMenu
  },
  props: {
    type: {
      type: String,
      default: 'resources',
      validator: (value) => {
        return ['offerings', 'inspiration', 'references', 'resources'].includes(
          value
        )
      }
    },
    parentType: {
      type: String,
      default: 'resources',
      validator: (value) => {
        return ['offering', 'inspiration', 'reference'].includes(value)
      }
    },
    ori: {
      type: Object,
      default: () => ({})
    },
    name: {
      type: String,
      default: ''
    },
    items: {
      type: Array,
      default: () => []
    },
    hasShowMore: {
      type: Boolean,
      default: true
    },
    removable: {
      type: Boolean,
      default: true
    },
    isOwner: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    cardComponent: {
      offerings: OfferingCard,
      inspiration: InspirationListItem,
      references: ReferenceListItem,
      resources: ORIResourceCard
    },
    gridCount: 3,
    showMore: false,
    removeLoading: []
  }),
  computed: {
    ...mapGetters(['canWriteOri', 'oriManagementEnabled']),
    activeFilters() {
      const filters = this.$route.query?.[`${this.type}-labels`]
      return filters ? (Array.isArray(filters) ? filters : [filters]) : []
    },
    extraResourceFilters() {
      return this.type === 'resources'
        ? [...this.resourceMimetypeFilter, ...this.resourceStatusFilter]
        : []
    },
    resourceMimetypeFilter() {
      let mimeTypeFilters = this.filteredItems.reduce((acc, item) => {
        if (
          !item.integrationfile?.mimetype ||
          acc.includes(item.integrationfile.mimetype)
        )
          return acc
        return [...acc, item.integrationfile.mimetype]
      }, [])
      mimeTypeFilters = mimeTypeFilters.reduce((acc, type) => {
        const typeName = mimetypeToTypeName[type]
        if (!typeName) return acc
        const existingType = acc.findIndex((t) => t.value === typeName)
        if (existingType === -1)
          return [...acc, { value: typeName, uuid: [type] }]
        else
          return acc.map((t, idx) =>
            idx === existingType ? { ...t, uuid: [...t.uuid, type] } : t
          )
      }, [])
      return [
        ...(mimeTypeFilters.length
          ? [
              {
                name: 'File type',
                uuid: 'mimetype',
                values: mimeTypeFilters
              }
            ]
          : [])
      ]
    },
    activeResourceMimeTypeFilters() {
      const filters = this.$route.query?.['resources-mimetype']
      return filters ? (Array.isArray(filters) ? filters : [filters]) : []
    },
    resourceStatusFilter() {
      const hasPublished = this.filteredItems.some(
        (item) => item.status === 'published'
      )
      const hasDraft = this.filteredItems.some(
        (item) => item.status === 'draft'
      )
      const values = [
        ...(hasPublished ? [{ uuid: 'published', value: 'Published' }] : []),
        ...(hasDraft ? [{ uuid: 'draft', value: 'Draft' }] : [])
      ]
      return [
        ...((this.canWriteOri || this.isOwner) && values.length
          ? [
              {
                name: 'Status',
                uuid: 'status',
                values
              }
            ]
          : [])
      ]
    },
    activeResourceStatusFilters() {
      const filters = this.$route.query?.['resources-status']
      return filters ? (Array.isArray(filters) ? filters : [filters]) : []
    },
    useGridDisplay() {
      return ['resources', 'offerings'].includes(this.type)
    },
    showMoreCutoff() {
      return this.useGridDisplay ? this.gridCount * 2 : 2
    },
    filteredItems() {
      return this.items.filter((i) => {
        const attributeFilter = (item) => {
          if (!this.activeFilters?.length) return true
          if (!item.attributes) return false
          const tags = item.attributes.reduce(
            (acc, att) => [...acc, ...att.values.map((v) => v.uuid)],
            []
          )
          return this.activeFilters.every((filter) => tags.includes(filter))
        }
        const mimetypeFilter = (item) => {
          if (!this.activeResourceMimeTypeFilters?.length) return true
          return this.activeResourceMimeTypeFilters.includes(
            item.integrationfile?.mimetype
          )
        }
        const statusFilter = (item) => {
          if (!this.activeResourceStatusFilters?.length) return true
          return this.activeResourceStatusFilters.includes(item.status)
        }
        return attributeFilter(i) && mimetypeFilter(i) && statusFilter(i)
      })
    },
    visibleItems() {
      return this.showMore || !this.hasShowMore
        ? this.filteredItems
        : this.filteredItems.slice(0, this.showMoreCutoff)
    },
    useDragGrid() {
      return (
        this.type === 'resources' ||
        (this.type === 'offerings' && this.parentType === 'offering')
      )
    },
    showAdd() {
      return this.canWriteOri || this.isOwner
    },
    addButtonText() {
      return `Add ${this.typeName(this.type)}`
    },
    isEmpty() {
      return !this.items.length
    },
    emptyMessage() {
      return {
        header: `${this.name} empty`,
        subheader: `No ${this.typeName(
          this.type,
          true
        )} linked to this ${this.typeName(this.parentType)} yet.`
      }
    },
    hasTempItems() {
      return this.items.some(i => !i.uuid)
    }
  },
  mounted() {
    this.checkGridCount()
    window.addEventListener('resize', this.checkGridCount)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkGridCount)
  },
  methods: {
    ...mapActions([
      'unlinkResourceFromOffering',
      'unlinkResourceFromInspiration',
      'unlinkResourceFromReference',
      'editOffering',
      'editInspiration',
      'editReference'
    ]),
    cardProps(item, idx) {
      switch (this.type) {
        case 'offerings':
          return {
            offering: item,
            clickable: true,
            showStatus: this.canWriteOri || this.isOwner
          }
        case 'inspiration':
          return {
            inspiration: item,
            clickable: true,
            showStatus: this.canWriteOri || this.isOwner
          }
        case 'references':
          return {
            reference: item,
            clickable: true,
            showStatus: this.canWriteOri || this.isOwner
          }
        case 'resources':
          return {
            resource: item,
            rank: idx + 1,
            location: `Section in detail page ${this.type}`,
            hasPreviewHover: ['video'].includes(item.type),
            highlight: item.highlight,
            canEdit: this.canWriteOri || this.isOwner
          }
        default:
          break
      }
    },
    typeName(type, plural = false) {
      const name = {
        offering: 'offering',
        offerings: 'offering',
        inspiration: 'inspirational content',
        references: 'case',
        reference: 'case',
        resources: 'content'
      }[type]
      return plural && !['inspiration', 'resources'].includes(type)
        ? `${name}s`
        : name
    },
    checkGridCount() {
      const el = document.getElementById(`${this.type}-contentgrid`)
      if (!el) return
      this.gridCount = window
        .getComputedStyle(el)
        ?.gridTemplateColumns?.split(' ')?.length
    },
    showRemove(item) {
      return (
        !this.oriManagementEnabled ||
        this.type !== 'resources' ||
        (this.type === 'resources' &&
          item.integrationfile?.creation_source !== 'generated' &&
          item.uuid)
      )
    },
    hasDownloadableMimetype(item) {
      return downloadableMimetypes.includes(
        item.integrationfile?.mimetype || ''
      )
    },
    async handleRemove(item) {
      try {
        this.removeLoading.push(item.uuid)
        const removalFunction =
          this.type === 'resources'
            ? {
                offering: this.unlinkResourceFromOffering,
                inspiration: this.unlinkResourceFromInspiration,
                reference: this.unlinkResourceFromReference
              }[this.parentType]
            : {
                offering: this.editOffering,
                inspiration: this.editInspiration,
                reference: this.editReference
              }[this.parentType]
        const itemsKey = {
          offerings: 'offerings',
          inspiration: 'inspirations',
          references: 'references'
        }[this.type]
        const itemIdProp = {
          offerings: 'offering_ids',
          inspiration: 'inspiration_ids',
          references: 'reference_ids'
        }[this.type]
        await removalFunction({
          workspace_id: this.$route.params.workspace_id,
          ori_id: this.ori.uuid,
          ...(this.type === 'resources'
            ? { resource_id: item.uuid }
            : {
                [itemIdProp]: this.ori[itemsKey]
                  .filter((i) => i.uuid !== item.uuid)
                  .map((i) => i.uuid)
              })
        })
        this.$emit('remove', item)
      } catch (e) {
        this.$console.debug(
          `Error removing ${this.type} from ${this.parentType}`,
          e
        )
        this.$toast.error(
          e,
          `removing ${this.typeName(this.type)} from ${this.typeName(
            this.parentType
          )}`
        )
      } finally {
        this.removeLoading = this.removeLoading.filter(
          (uuid) => uuid !== item.uuid
        )
      }
    }
  }
}
</script>

<style lang="scss">
.ori-section {
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;

  &-header {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 1rem;

    &-title {
      font-size: 1.25rem;
      font-weight: 700;
      color: #303032;
    }

    &-info {
      height: 1.2rem;
      opacity: 0.5;
    }

    &-btns {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      margin-left: auto;
      gap: 0.5rem;
    }
  }

  &-content {
    &.is-grid {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(30rem, 1fr));
      gap: 1rem;

      &.compact-grid {
        grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
      }
    }

    &:not(.is-grid) {
      gap: 1rem;
      display: flex;
      flex-flow: column nowrap;
    }

    &-item {
      position: relative;
      height: 100%;
      width: 100%;

      &-btns {
        position: absolute;
        top: 1rem;
        right: 1rem;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        gap: 0.5rem;
      }
    }

    &-add {
      border: 1px dashed rgba(#000, 8%);
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 5rem;

      &.in-grid {
        min-height: 10rem;
      }

      &:hover {
        cursor: pointer;
        border: 1px dashed rgba(#000, 16%);
        box-shadow: 0px 4px 8px 0px rgba(#000, 10%);
      }

      &-icon {
        height: 1.5rem;
        opacity: 0.5;
      }
    }
  }

  &-empty {
    color: #60666b;
    width: 100%;
    padding: 1.25rem 1rem;
    border-radius: 6px;
    background: rgba(#000, 0.02);
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;

    &-header {
      font-size: 1.15rem;
      font-weight: 700;
    }

    &-subheader {
      color: #60666b;
      margin-bottom: 0.5rem;
    }
  }

  &-showmore {
    align-self: center;
  }
}
</style>
